export const VALID_GUESSES = [
  'dunku',
  'mäuke',
  'öpper',
  'bysst',
  'chrüz',
  'pfaff',
  'ghout',
  'jager',
  'bruef',
  'kifft',
  'gjuzt',
  'klare',
  'ruuch',
  'fäuti',
  'chute',
  'housi',
  'heiri',
  'gwane',
  'sövel',
  'hegle',
  'pisse',
  'idiot',
  'güebt',
  'lüpfe',
  'bauge',
  'bädle',
  'pappa',
  'simpu',
  'derig',
  'ätsch',
  'bänne',
  'nidsi',
  'tämpu',
  'länge',
  'sölle',
  'bräme',
  'bräll',
  'gusle',
  'backe',
  'leids',
  'muuse',
  'becke',
  'ascht',
  'foopa',
  'hüser',
  'trini',
  'chölm',
  'nible',
  'hohle',
  'spore',
  'sänku',
  'tüele',
  'putzt',
  'rüüme',
  'goofe',
  'bosge',
  'dusle',
  'runde',
  'stile',
  'jätte',
  'vrene',
  'behaa',
  'essig',
  'püeze',
  'nucke',
  'saage',
  'gseht',
  'kondi',
  'lande',
  'lufte',
  'jöggu',
  'schüp',
  'jagge',
  'päppu',
  'frein',
  'sauft',
  'louch',
  'louft',
  'morge',
  'junge',
  'nötli',
  'gnamt',
  'glutz',
  'wärli',
  'dasch',
  'gäute',
  'spitz',
  'zünte',
  'sädle',
  'folge',
  'ruume',
  'bebee',
  'ornig',
  'duzis',
  'breit',
  'dünku',
  'fisle',
  'bären',
  'ängel',
  'chalb',
  'plämu',
  'frömd',
  'budle',
  'rysst',
  'uberi',
  'stäle',
  'bride',
  'dorfi',
  'fryse',
  'budig',
  'gider',
  'naagä',
  'mooss',
  'jänte',
  'tifig',
  'gleis',
  'gheit',
  'mäude',
  'enang',
  'kürtu',
  'ueche',
  'borge',
  'haare',
  'ghöre',
  'mejet',
  'zappi',
  'ylüte',
  'toube',
  'chiut',
  'zsäge',
  'zäpfe',
  'weize',
  'brunz',
  'chlei',
  'güxli',
  'füess',
  'müffi',
  'froue',
  'bügle',
  'darta',
  'bolei',
  'bofel',
  'vögle',
  'chelm',
  'pfydi',
  'gemel',
  'pille',
  'adieu',
  'gglyr',
  'bybii',
  'punkt',
  'mitti',
  'desab',
  'hüete',
  'büppi',
  'kiffe',
  'räbel',
  'dinne',
  'nassi',
  'fötzu',
  'märmu',
  'nüüte',
  'niete',
  'rouch',
  'fasch',
  'lüfte',
  'bewys',
  'ringi',
  'gagle',
  'änggu',
  'verby',
  'gebse',
  'süsch',
  'blees',
  'lappi',
  'flick',
  'efang',
  'chüdi',
  'apart',
  'hünte',
  'ysätz',
  'turbe',
  'wylet',
  'tümmi',
  'madli',
  'büffe',
  'chips',
  'glodu',
  'nache',
  'imene',
  'gwett',
  'packt',
  'mocke',
  'hülse',
  'brcho',
  'lööli',
  'allne',
  'mähne',
  'wadli',
  'graue',
  'wurme',
  'batte',
  'pfupf',
  'hänke',
  'netze',
  'groue',
  'söule',
  'mangi',
  'ripse',
  'düüme',
  'heble',
  'haubi',
  'pfüpf',
  'jufli',
  'buese',
  'ougen',
  'fecke',
  'blind',
  'rieme',
  'furze',
  'bitze',
  'täfel',
  'seili',
  'pögge',
  'folle',
  'stäge',
  'beeri',
  'huuri',
  'gunte',
  'becki',
  'ditti',
  'tüpfe',
  'ärmli',
  'gmüet',
  'röike',
  'nächi',
  'hange',
  'greut',
  'roggu',
  'luure',
  'letzi',
  'motze',
  'umtue',
  'rüuze',
  'wosch',
  'rüefe',
  'übere',
  'ryffe',
  'chaub',
  'chind',
  'abzie',
  'züber',
  'zabli',
  'längi',
  'pfääj',
  'ämdet',
  'hibne',
  'multe',
  'heiti',
  'gable',
  'grund',
  'acher',
  'fasel',
  'dyche',
  'rülze',
  'finde',
  'möuch',
  'hämpu',
  'momol',
  'butze',
  'bhänk',
  'gurli',
  'halse',
  'zleid',
  'pfyle',
  'duume',
  'sorge',
  'hause',
  'voila',
  'nisle',
  'liebi',
  'büebu',
  'päple',
  'danke',
  'zysse',
  'porze',
  'bürzi',
  'glych',
  'permi',
  'darum',
  'liede',
  'dises',
  'allmi',
  'eklig',
  'gööli',
  'suure',
  'püder',
  'totze',
  'gange',
  'polet',
  'glade',
  'aarig',
  'styff',
  'sydig',
  'würze',
  'feete',
  'hirne',
  'bidon',
  'dunke',
  'püffu',
  'guntu',
  'huppi',
  'talpe',
  'duure',
  'naaga',
  'tüpfi',
  'blang',
  'villa',
  'mutte',
  'satze',
  'siech',
  'ggita',
  'möget',
  'keini',
  'tubel',
  'redli',
  'bäsne',
  'däwäg',
  'ebcho',
  'glöuf',
  'puure',
  'gnage',
  'bolle',
  'grübi',
  'stöck',
  'zwöit',
  'mändu',
  'gidon',
  'wääge',
  'miggi',
  'uring',
  'miech',
  'ramse',
  'poche',
  'banke',
  'reise',
  'gäntu',
  'zügle',
  'dache',
  'glatt',
  'niggu',
  'beidi',
  'milch',
  'töktu',
  'tropf',
  'gholt',
  'nümme',
  'arfel',
  'gödel',
  'nuggi',
  'handy',
  'pfuus',
  'münze',
  'trine',
  'joggi',
  'fridu',
  'öugle',
  'fätze',
  'gumpe',
  'hoboo',
  'nahär',
  'lingg',
  'lache',
  'bedle',
  'bäggu',
  'heepe',
  'gouch',
  'äschi',
  'putze',
  'hutte',
  'aanäh',
  'höiet',
  'läche',
  'bring',
  'facku',
  'ander',
  'spädu',
  'gründ',
  'täber',
  'loufe',
  'säges',
  'biete',
  'mängi',
  'puckt',
  'tüppu',
  'nöime',
  'flirz',
  'artig',
  'tuele',
  'brüef',
  'alkis',
  'maale',
  'dunde',
  'loube',
  'buebe',
  'glööu',
  'sädel',
  'rääre',
  'noule',
  'still',
  'laggu',
  'järig',
  'lampe',
  'bläch',
  'akkle',
  'buess',
  'gorps',
  'liebu',
  'üerti',
  'täfer',
  'rügge',
  'lööke',
  'gisch',
  'taape',
  'juhee',
  'fusel',
  'gräbt',
  'seigu',
  'chäch',
  'chopf',
  'törfe',
  'siffe',
  'stier',
  'baabi',
  'nussi',
  'sugge',
  'emahi',
  'rööze',
  'ufzgi',
  'murxe',
  'deeri',
  'nüele',
  'birli',
  'houze',
  'fitze',
  'bögle',
  'stübe',
  'gigle',
  'puffe',
  'fähle',
  'zweig',
  'seike',
  'gäbig',
  'staue',
  'arfle',
  'chley',
  'prügu',
  'batze',
  'fälli',
  'aschi',
  'nicht',
  'sones',
  'taggu',
  'büsch',
  'stupf',
  'ekeis',
  'güezi',
  'manne',
  'stich',
  'taupe',
  'disse',
  'ender',
  'murbe',
  'öpfel',
  'musig',
  'trügg',
  'churz',
  'malör',
  'müeti',
  'topfe',
  'mungg',
  'füdig',
  'tüüfu',
  'mamis',
  'leide',
  'strau',
  'gledu',
  'bache',
  'uusga',
  'ample',
  'couch',
  'troom',
  'gelte',
  'sturm',
  'tippu',
  'tüüme',
  'aacho',
  'henne',
  'kante',
  'bette',
  'äiwäg',
  'töifu',
  'passe',
  'lääre',
  'fläme',
  'disig',
  'meine',
  'dänki',
  'lehrt',
  'flöte',
  'masch',
  'gsang',
  'lööte',
  'safte',
  'zable',
  'chläb',
  'statt',
  'ghoue',
  'worde',
  'chnöi',
  'märte',
  'serre',
  'büeli',
  'wäger',
  'chegu',
  'brünu',
  'pämsu',
  'bärze',
  'rigel',
  'saubi',
  'ämtli',
  'pysse',
  'chrut',
  'uflat',
  'gwoge',
  'rauig',
  'zuber',
  'stift',
  'wärch',
  'fahne',
  'möisi',
  'dänne',
  'partu',
  'dicki',
  'hansi',
  'jodle',
  'gotti',
  'abela',
  'zuega',
  'pudle',
  'chüeu',
  'stand',
  'junte',
  'löffu',
  'nydig',
  'täätu',
  'geits',
  'gsöff',
  'namse',
  'dörfe',
  'nomau',
  'kenne',
  'fouge',
  'grupe',
  'gchöz',
  'nobis',
  'tuble',
  'titte',
  'rägne',
  'öiger',
  'hocke',
  'cheue',
  'chefi',
  'ystee',
  'gnagi',
  'güegi',
  'äpere',
  'gring',
  'tante',
  'güggu',
  'jakob',
  'deere',
  'vatti',
  'lisme',
  'hefte',
  'sinzu',
  'trübu',
  'ustaa',
  'fimel',
  'yfuxe',
  'hohli',
  'tümpu',
  'büeze',
  'bisse',
  'zisch',
  'plimm',
  'mängs',
  'ywyse',
  'abhaa',
  'affig',
  'tüüri',
  'buume',
  'suppe',
  'hüsli',
  'alles',
  'gunze',
  'heite',
  'schaf',
  'rappu',
  'bützi',
  'daher',
  'fräve',
  'wäspi',
  'gsood',
  'näggu',
  'iwybe',
  'pöble',
  'suuri',
  'sunne',
  'fäger',
  'welem',
  'janei',
  'ääper',
  'töubi',
  'gööle',
  'fläre',
  'gusel',
  'benää',
  'hüüle',
  'busch',
  'umsta',
  'halte',
  'züble',
  'wider',
  'guger',
  'geils',
  'bocke',
  'bonör',
  'hümpu',
  'fääre',
  'mamas',
  'türgg',
  'füdli',
  'lätze',
  'paffe',
  'hööch',
  'muure',
  'ylyre',
  'zybet',
  'schön',
  'masel',
  'lybli',
  'gugle',
  'wäuts',
  'näggi',
  'erger',
  'püezt',
  'bysse',
  'glänk',
  'züger',
  'bbout',
  'bääbi',
  'ysere',
  'gfägt',
  'möösi',
  'plamp',
  'chehr',
  'chupe',
  'salbe',
  'verga',
  'chöpf',
  'ziger',
  'hurde',
  'häufe',
  'gryne',
  'ewägg',
  'gfüel',
  'gömer',
  'chemp',
  'techu',
  'gnägi',
  'hälls',
  'häxli',
  'notti',
  'quitt',
  'toggu',
  'mämmi',
  'lötig',
  'branz',
  'guggi',
  'wifle',
  'znüni',
  'dorne',
  'strou',
  'bättu',
  'tuume',
  'bousi',
  'nifle',
  'fryti',
  'löger',
  'weune',
  'müpfe',
  'bisch',
  'säute',
  'huble',
  'vögel',
  'jänei',
  'zwick',
  'ghudu',
  'föifi',
  'kasse',
  'ührli',
  'würke',
  'dyner',
  'läder',
  'ablaa',
  'luege',
  'rüere',
  'gärbi',
  'füdle',
  'rugge',
  'fürig',
  'yribe',
  'toope',
  'chini',
  'lusch',
  'reder',
  'komod',
  'chugi',
  'galle',
  'gääch',
  'tölgg',
  'welle',
  'geili',
  'touer',
  'eiget',
  'chüel',
  'fraas',
  'fläck',
  'päägg',
  'hurti',
  'tööpe',
  'theek',
  'meiti',
  'gigel',
  'ebsie',
  'wampe',
  'lokal',
  'uftue',
  'hubel',
  'ghütt',
  'gürbi',
  'amänd',
  'möbel',
  'hopps',
  'winde',
  'apere',
  'äuwää',
  'chole',
  'neuer',
  'immer',
  'nöggi',
  'fahri',
  'bonze',
  'unger',
  'gwüss',
  'lamme',
  'noggi',
  'sääje',
  'myner',
  'jasse',
  'yhage',
  'gfräs',
  'änere',
  'nääje',
  'arsch',
  'chris',
  'hütli',
  'spöiz',
  'gätzi',
  'lälle',
  'fähri',
  'pfösu',
  'leite',
  'gönne',
  'sürmu',
  'flach',
  'chilt',
  'heuke',
  'flade',
  'matte',
  'dünze',
  'hutti',
  'grufe',
  'stutz',
  'flegu',
  'jäner',
  'chram',
  'fänku',
  'dimal',
  'würfu',
  'zahni',
  'zünti',
  'verha',
  'träll',
  'figge',
  'holze',
  'gäube',
  'düwee',
  'sufer',
  'waggu',
  'reiti',
  'krach',
  'hätti',
  'uhung',
  'kaput',
  'rutti',
  'näppu',
  'lumpe',
  'moinz',
  'rifle',
  'botte',
  'ründi',
  'zelle',
  'stück',
  'memme',
  'achti',
  'chumm',
  'gwise',
  'zutue',
  'gfüeu',
  'greme',
  'chitt',
  'picke',
  'aaper',
  'boozi',
  'choge',
  'gyble',
  'ghäbt',
  'trage',
  'ragle',
  'unten',
  'ische',
  'zahme',
  'gymme',
  'lappe',
  'heile',
  'uflig',
  'fydel',
  'huttu',
  'teigg',
  'äuele',
  'nasse',
  'tunke',
  'fuere',
  'stock',
  'moore',
  'nunni',
  'ching',
  'front',
  'täuer',
  'mösch',
  'heuge',
  'chuze',
  'büezt',
  'hallo',
  'zeige',
  'wölle',
  'wärde',
  'bärge',
  'mager',
  'zoobe',
  'selbi',
  'sälte',
  'nagle',
  'möndu',
  'zuuge',
  'glesu',
  'ställ',
  'ischs',
  'brätt',
  'neime',
  'lödli',
  'harze',
  'chuum',
  'choli',
  'dräck',
  'escht',
  'stadt',
  'gross',
  'fröie',
  'ghääl',
  'elter',
  'chüng',
  'blutt',
  'buuss',
  'däätu',
  'böögg',
  'ylade',
  'pöili',
  'häsli',
  'öppis',
  'soode',
  'trääf',
  'spält',
  'untan',
  'hunge',
  'mugge',
  'enger',
  'müsli',
  'sappe',
  'chübu',
  'stund',
  'pappi',
  'auwää',
  'dampf',
  'süggu',
  'nüggu',
  'steue',
  'dünzi',
  'müuch',
  'häbdi',
  'himpi',
  'öigli',
  'fyduz',
  'litze',
  'hilbe',
  'mysex',
  'umegä',
  'stuud',
  'buure',
  'mogle',
  'gomfi',
  'dychi',
  'fürsi',
  'tafle',
  'miggu',
  'bödig',
  'stolz',
  'nydle',
  'vreni',
  'mandi',
  'tubak',
  'brüue',
  'läbig',
  'pruef',
  'nächt',
  'döktu',
  'cheib',
  'böppu',
  'hesch',
  'böses',
  'horne',
  'geiss',
  'pfire',
  'gredi',
  'tobel',
  'länte',
  'kompi',
  'eisse',
  'zweie',
  'trüeb',
  'räble',
  'tünke',
  'chunt',
  'rouke',
  'liebs',
  'zrügg',
  'selte',
  'nacht',
  'lööle',
  'troge',
  'gheie',
  'hämmi',
  'gingg',
  'chlys',
  'guets',
  'apero',
  'hoopi',
  'gimpu',
  'megge',
  'fründ',
  'zeise',
  'pfüdi',
  'frygo',
  'helke',
  'blätz',
  'pfure',
  'gäggu',
  'zääje',
  'gjätt',
  'hälli',
  'hänsu',
  'strub',
  'aagaa',
  'grufi',
  'stüpf',
  'fräch',
  'rigle',
  'plage',
  'rübis',
  'hoble',
  'schär',
  'schla',
  'luenz',
  'worbe',
  'zöttu',
  'chise',
  'trämu',
  'ranze',
  'amene',
  'rüeme',
  'chart',
  'achte',
  'bääje',
  'beide',
  'gueti',
  'fungg',
  'füraa',
  'dummi',
  'übers',
  'akkus',
  'momou',
  'böisi',
  'säget',
  'lueme',
  'rolle',
  'feiss',
  'tägli',
  'rächt',
  'polig',
  'kener',
  'parat',
  'chorb',
  'ruedi',
  'hönne',
  'babys',
  'arvel',
  'wäutu',
  'welne',
  'goume',
  'huere',
  'affli',
  'chatz',
  'spöie',
  'wärte',
  'luemi',
  'leidi',
  'beize',
  'dumme',
  'rüedu',
  'herte',
  'blybt',
  'bälle',
  'gugge',
  'enand',
  'gäbel',
  'eewig',
  'däich',
  'züügs',
  'gryte',
  'guene',
  'gabli',
  'kippe',
  'pläfe',
  'burdi',
  'bling',
  'güüde',
  'sable',
  'plämm',
  'benäh',
  'bysle',
  'bluet',
  'bätzi',
  'lugge',
  'aagah',
  'rohre',
  'nessi',
  'heiku',
  'marei',
  'wohle',
  'löter',
  'fragi',
  'chöie',
  'duuch',
  'macht',
  'beite',
  'spatz',
  'dusse',
  'gäbti',
  'pinte',
  'hütze',
  'bhäng',
  'pössu',
  'packe',
  'kable',
  'fädle',
  'sträu',
  'weder',
  'rätig',
  'treit',
  'finke',
  'metzg',
  'hälfe',
  'gertu',
  'gytig',
  'späte',
  'füüre',
  'dasme',
  'sible',
  'wääje',
  'gööni',
  'zyble',
  'trybe',
  'migge',
  'spyse',
  'hudle',
  'binde',
  'wöume',
  'geine',
  'marie',
  'gäder',
  'tigge',
  'tunze',
  'ermel',
  'zaabe',
  'risle',
  'hämel',
  'pouse',
  'sowyt',
  'negle',
  'echly',
  'wösch',
  'nürze',
  'zymme',
  'xunde',
  'seich',
  'vogel',
  'dasle',
  'flänz',
  'brügg',
  'ruech',
  'weles',
  'wohär',
  'hausi',
  'grosi',
  'stänz',
  'trali',
  'hagle',
  'pooge',
  'nöjer',
  'südle',
  'grase',
  'uhüng',
  'räson',
  'wydle',
  'plädu',
  'häbet',
  'finge',
  'zunge',
  'allem',
  'hagel',
  'hölle',
  'rapse',
  'gnifu',
  'boppu',
  'stall',
  'ybund',
  'troog',
  'gebsi',
  'lehre',
  'zahne',
  'zwuri',
  'zupfe',
  'tolgg',
  'wägli',
  'nomen',
  'eseff',
  'ylege',
  'haage',
  'läärs',
  'ämter',
  'näsle',
  'säche',
  'tüble',
  'brami',
  'ghüül',
  'secku',
  'miuch',
  'penne',
  'wöhli',
  'gürbe',
  'falle',
  'kicke',
  'träfe',
  'göggs',
  'wysse',
  'rande',
  'püntu',
  'lewat',
  'vorig',
  'glööl',
  'recke',
  'ghüdi',
  'forne',
  'zdorf',
  'passt',
  'gseit',
  'chäse',
  'fiele',
  'nysch',
  'goggs',
  'chänu',
  'süchu',
  'fisel',
  'winen',
  'frage',
  'ysatz',
  'weich',
  'göppu',
  'brügi',
  'zuche',
  'bruch',
  'aubes',
  'grösu',
  'habch',
  'lääck',
  'brave',
  'jufle',
  'giiss',
  'sitze',
  'dotze',
  'abtue',
  'trümu',
  'baggu',
  'tanti',
  'gitzi',
  'apriu',
  'töibi',
  'bärin',
  'giele',
  'chümi',
  'züttu',
  'gräbu',
  'hächu',
  'fahre',
  'wobei',
  'souft',
  'spalt',
  'helde',
  'sürme',
  'chöle',
  'umlaa',
  'aahou',
  'büüre',
  'ränze',
  'helte',
  'byble',
  'ärfle',
  'puess',
  'laffe',
  'pärze',
  'tribu',
  'derby',
  'kalet',
  'gropp',
  'stifu',
  'hoope',
  'gmües',
  'schül',
  'zytig',
  'kluft',
  'paari',
  'mänge',
  'törle',
  'lümpe',
  'ghält',
  'druus',
  'hirte',
  'sooli',
  'tööri',
  'geute',
  'garte',
  'desch',
  'höizu',
  'hirse',
  'rauft',
  'monet',
  'nyffi',
  'quark',
  'angst',
  'ungen',
  'busle',
  'ablah',
  'kennt',
  'füüli',
  'natel',
  'chalt',
  'nouss',
  'inere',
  'määje',
  'mange',
  'grebt',
  'ufzie',
  'brägu',
  'süffu',
  'süüne',
  'pygge',
  'taget',
  'gsüün',
  'fürnä',
  'fäcke',
  'muggi',
  'ärfel',
  'gfell',
  'ferig',
  'mache',
  'gamme',
  'blybe',
  'dervo',
  'vergä',
  'färle',
  'trüeu',
  'wyber',
  'rysse',
  'barte',
  'dampe',
  'fäuge',
  'niene',
  'lyyre',
  'höger',
  'chere',
  'ösche',
  'gfotz',
  'gälle',
  'dumms',
  'protz',
  'ywybe',
  'küsse',
  'ängge',
  'wädle',
  'hicke',
  'dähle',
  'haute',
  'aagno',
  'dinge',
  'abgah',
  'poppu',
  'chuli',
  'zapfe',
  'tampe',
  'gaage',
  'wüsse',
  'hampi',
  'zimet',
  'figgi',
  'hegel',
  'ligge',
  'modis',
  'müede',
  'bregg',
  'dänke',
  'züpfe',
  'tupee',
  'pryse',
  'beiji',
  'grien',
  'putle',
  'aagää',
  'rueje',
  'stili',
  'goudi',
  'urhab',
  'luter',
  'luegt',
  'honig',
  'lötet',
  'saube',
  'styge',
  'pfade',
  'helge',
  'tuets',
  'chino',
  'püppi',
  'rumpf',
  'sesch',
  'troon',
  'ochli',
  'myyde',
  'härpu',
  'hinge',
  'machi',
  'hefti',
  'fähne',
  'keine',
  'fraag',
  'pumpi',
  'luuse',
  'bumse',
  'guete',
  'niele',
  'ufsta',
  'schyt',
  'nyffe',
  'glöif',
  'späck',
  'flute',
  'wylti',
  'chlyn',
  'salbi',
  'pladi',
  'rible',
  'löfti',
  'wädel',
  'stütz',
  'mädle',
  'mättu',
  'nöjis',
  'tünkt',
  'wyter',
  'spile',
  'fasse',
  'rüffu',
  'märit',
  'bääre',
  'gaffe',
  'rugel',
  'chare',
  'spyri',
  'lysli',
  'hirni',
  'abwäg',
  'rosse',
  'wagle',
  'rünne',
  'grüfi',
  'paris',
  'blofi',
  'chaut',
  'loche',
  'brate',
  'zuune',
  'gwaag',
  'chlee',
  'nuele',
  'brönz',
  'pünkt',
  'föhne',
  'april',
  'hamme',
  'sächs',
  'kackt',
  'küder',
  'himpe',
  'mutze',
  'links',
  'renne',
  'häbig',
  'dünke',
  'huure',
  'ruure',
  'blöji',
  'anger',
  'dunge',
  'plunz',
  'zimis',
  'redle',
  'boole',
  'chäsi',
  'brige',
  'gsodi',
  'horeb',
  'byder',
  'rääss',
  'stang',
  'duuss',
  'usegä',
  'suber',
  'blase',
  'brüel',
  'mytli',
  'urfel',
  'machs',
  'trouf',
  'weeka',
  'gäuet',
  'trüli',
  'pfung',
  'turte',
  'kundi',
  'wuche',
  'graad',
  'hoger',
  'jungs',
  'usega',
  'zetti',
  'tyche',
  'gstüd',
  'bütti',
  'gläck',
  'chybe',
  'titti',
  'chose',
  'dorfe',
  'trabe',
  'sudel',
  'tuech',
  'greed',
  'nider',
  'ganze',
  'zägge',
  'gsung',
  'paggu',
  'möffe',
  'emänd',
  'chonu',
  'fisch',
  'bofle',
  'hümpe',
  'gaggo',
  'bieli',
  'fryne',
  'gotte',
  'gäuit',
  'prost',
  'todet',
  'ustue',
  'eiche',
  'socke',
  'himel',
  'blibe',
  'afang',
  'zügli',
  'hacke',
  'baaje',
  'fuess',
  'nöchi',
  'hinde',
  'gagel',
  'schue',
  'häble',
  'pypse',
  'gsüff',
  'zähni',
  'haber',
  'etwas',
  'lämpe',
  'moudi',
  'ghänk',
  'wäret',
  'räche',
  'höiel',
  'frudi',
  'mueti',
  'ustig',
  'buech',
  'sider',
  'näble',
  'hasle',
  'zette',
  'fräne',
]
